import { createStyles, Transition } from '@mantine/core';
import { useEffect, useState } from 'react';
import logo from '../logo.svg';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 1000
  },
  image: {
    maxWidth: 500,
    width: '50vh'
  }
}));

const logoFade = {
  in: { opacity: 1 },
  out: { opacity: 0 },
  transitionProperty: 'opacity',
};

export default function Welcome() {
  const [mounted, setMounted] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Transition mounted={mounted} transition={logoFade} duration={500} timingFunction="forwards">
      {(styles) => (
        <div style={styles} className={classes.root}>
          <img className={classes.image} src={logo} alt="Logo" />
        </div>
      )}
    </Transition>

  )
}