import { Button, Text, Textarea, TextInput, createStyles, Container } from '@mantine/core';
import { useState } from 'react';
import useLazyFetch from '../hooks/useLazyFetch';
import { useNotifications } from '@mantine/notifications';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    height: '100%'
  },
  input: {
    marginTop: 20
  },
  submit: {
    marginTop: 20
  }
}));

export default function Contact() {
  const notifications = useNotifications();
  const { classes } = useStyles();
  const [makeFetch, { loading }] = useLazyFetch({
    onSuccess: () => {
      notifications.showNotification({
        title: 'Success',
        color: 'green',
        message: 'Your message was successfully sent.',
      })
      reset();
    },
    onError: () => {
      notifications.showNotification({
        title: 'Error',
        color: 'red',
        message: 'There was an error sending your message. Please try again.',
      })
    }
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const reset = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  }

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !message) return null;

    makeFetch({
      url: process.env.REACT_APP_CONTACT_URL,
      options: {
        method: "POST",
        headers: {
          'x-api-key': process.env.REACT_APP_CONTACT_SECRET
        },
        body: JSON.stringify({
          subject: process.env.REACT_APP_CONTACT_SUBJECT,
          source: process.env.REACT_APP_CONTACT_SOURCE,
          destination: [process.env.REACT_APP_CONTACT_DESTINATION],
          name: `${firstName} ${lastName}`,
          emailAddress: email,
          message
        }),
      },
    });
  }

  return (
    <Container className={classes.root} size="sm">
      <Text size="sm" align="center">Contact Willie Cole by phone at: (310) 990-5101.</Text>
      <form onSubmit={onSubmit} >
        <TextInput
          className={classes.input}
          placeholder="First Name"
          size="sm"
          required
          value={firstName}
          onChange={(event) => setFirstName(event.currentTarget.value)}
        />
        <TextInput
          className={classes.input}
          placeholder="Last Name"
          size="sm"
          required
          value={lastName}
          onChange={(event) => setLastName(event.currentTarget.value)}
        />
        <TextInput
          className={classes.input}
          placeholder="Email Address"
          size="sm"
          required
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value)}
        />
        <Textarea
          className={classes.input}
          placeholder="Message"
          required
          autosize
          minRows={2}
          maxRows={4}
          value={message}
          onChange={(event) => setMessage(event.currentTarget.value)}
        />
        <Button
          className={classes.submit}
          loading={loading}
          color="dark"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Container>
  )
}