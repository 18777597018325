import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import PhotosPage from "./pages/Photos";

const navigation = [
  {
    title: "Home",
    path: "/",
    component: Home,
  },
  {
    title: "Interiors",
    path: "/interiors",
    component: PhotosPage,
  },
  {
    title: "Exteriors",
    path: "/exteriors",
    component: PhotosPage,
  },
  {
    title: "Published",
    path: "/published",
    component: PhotosPage,
  },
  {
    title: "Still Life",
    path: "/still-life",
    component: PhotosPage,
  },
  {
    title: "My Art",
    path: "/my-art",
    component: PhotosPage,
  },
  {
    title: "About",
    path: "/about",
    component: About,
  },
  {
    title: "Contact",
    path: "/contact",
    component: Contact,
  },
];

export default navigation;
