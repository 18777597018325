import React, { useEffect } from 'react';
import useLazyFetch from '../hooks/useLazyFetch';

const apiUrl = process.env.REACT_APP_SETTINGS_URL;

declare global {
  interface SettingsContextProps {
    loading: boolean;
    error: string;
    data: null | SettingsData
  }

  interface SettingsData {
    [key: string]: SettingsImage[]
  }

  interface SettingsImage {
    url: string;
    key: string;
    alt: string;
  }
}

export const SettingsContext = React.createContext<SettingsContextProps>({
  loading: false,
  error: '',
  data: null
})

export default function SettingsProvider(props: { children: React.ReactNode }) {
  const [makeFetch, { data, loading, error }] = useLazyFetch({});

  useEffect(() => {
    makeFetch({ url: `${apiUrl}/settings` })
  }, []);

  return (
    <SettingsContext.Provider value={{
      data: data?.data,
      loading,
      error
    }}>
      {props.children}
    </SettingsContext.Provider>
  )
}