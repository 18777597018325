import { Image, ActionIcon } from "@mantine/core";
import React, { useContext } from "react";
import { SettingsContext } from "../providers/SettingsProvider";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const arrow = (position: 'left' | 'right'): any => ({
  position: 'absolute',
  top: '50%',
  translate: '-50%',
  [position]: 0,
  zIndex: 5
})

function NextArrow({ onClick }: any) {
  return (
    <ActionIcon
      color="gray"
      onClick={onClick}
      size="lg"
      style={arrow('right')}
      variant="light"
      radius={0}
    >
      <FiChevronRight style={{ width: 35, height: 35 }} />
    </ActionIcon>
  )
}

function PrevArrow({ onClick }: any) {
  return (
    <ActionIcon
      color="gray"
      onClick={onClick}
      size="lg"
      style={arrow('left')}
      variant="light"
      radius={0}
    >
      <FiChevronLeft style={{ width: 35, height: 35 }} />
    </ActionIcon>
  )
}

export default function Photos({ title }: { title: string }) {
  const { data } = useContext(SettingsContext);
  const settings: Settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "progressive",
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const photos = data ? data[title.split(' ').join('-').toLowerCase()] : [];

  if (!photos || !photos.length) return null;

  return (
    <Slider {...settings} >
      {photos.map((image, index) => (
        <Image
          key={image.key}
          radius="md"
          src={image.url}
          alt={`${title} image number ${index}`}
          fit="contain"
          width="100%"
          height="calc(100vh - 50px)"
          style={{
            display: 'block'
          }}
        />
      ))}
    </Slider>
  )
}