import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import React from "react";

const theme: MantineThemeOverride = {
  fontFamily: 'Open Sans, sans-serif',
  // black: '212121',
  // primaryColor: '616161',
  headings: {
    fontFamily: 'Open Sans, sans-serif',
    sizes: {
      h1: { fontSize: 42, lineHeight: 44 },
      h2: { fontSize: 38, lineHeight: 40 },
      h3: { fontSize: 34, lineHeight: 36 },
      h4: { fontSize: 30, lineHeight: 32 },
      h5: { fontSize: 26, lineHeight: 28 },
      h6: { fontSize: 22, lineHeight: 24 }
    }
  }
}

export default function ThemeProvider(props: { children: React.ReactNode }) {
  return (
    <MantineProvider theme={theme} withNormalizeCSS>
      {props.children}
    </MantineProvider>
  )
}