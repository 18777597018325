import { Anchor, createStyles, Navbar } from '@mantine/core';
import logo from '../logo.svg';
import { Link } from "react-router-dom";

const useStyles = createStyles(() => ({
  anchor: {
    color: '#212121',
    fontSize: '16px',
    lineHeight: '18px',
    fontFamily: 'Fjalla One, sans-serif',
    padding: '7px 0',
    position: 'relative',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',

      '& span': {
        width: '100%'
      }
    },

    '& span': {
      background: 'rgba(0, 0, 0, 0.6)',
      width: 0,
      height: '1px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      transition: 'width .3s',
    }
  }
}));

export default function DesktopHeader() {
  const { classes } = useStyles();
  const sidebarWidth = 200;

  return (
    <div>
      <Navbar
        width={{ base: sidebarWidth }}
        style={{ minWidth: sidebarWidth, borderRight: 0 }}
        padding="lg"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: 50
          }}
        >
          <Anchor component={Link} to={'/'}>
            <img src={logo} alt="Logo" />
          </Anchor>
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              padding: 0,
            }}
          >
            <Anchor className={classes.anchor} component={Link} to={'/interiors'} >
              Interiors
              <span />
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/exteriors'} >
              Exteriors
              <span />
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/published'} >
              Published
              <span />
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/still-life'} >
              Still Life
              <span />
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/my-art'} >
              My Art
              <span />
            </Anchor>
          </ul>

          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              padding: 0,
            }}
          >
            <Anchor className={classes.anchor} component={Link} to={'/about'} >
              About
              <span />
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/contact'} >
              Contact
              <span />
            </Anchor>
          </ul>
        </div>
      </Navbar>
    </div>
  )
}