import { AppShell } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { NotificationsProvider } from '@mantine/notifications';
import ThemeProvider from './ThemeProvider';
import pages from './pages';
import SettingsProvider from './providers/SettingsProvider';
import { useInterval, useMediaQuery, useViewportSize } from '@mantine/hooks';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import Welcome from './pages/Welcome';

function App() {
  const [showWelcome, setShowWelcome] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const interval = useInterval(() => setSeconds((s) => s + 1), 1000);
  const matches = useMediaQuery('(min-width: 900px)');
  const { width } = useViewportSize();
  const sidebarWidth = 200;

  useEffect(() => {
    interval.start();

    return interval.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seconds === 3) {
      interval.stop()
      setShowWelcome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);


  if (showWelcome) {
    return (
      <Welcome />
    )
  }


  return (
    <ThemeProvider>
      <NotificationsProvider>
        <SettingsProvider>
          <BrowserRouter>
            <AppShell
              padding="sm"
              header={matches ? undefined : <MobileHeader />}
              navbar={matches ? <DesktopHeader /> : undefined}
              styles={{
                main: {
                  width: width - sidebarWidth - 8,
                  maxHeight: '100vh'
                },
                root: {
                  maxWidth: '100%'
                }
              }}
            >
              <Routes>
                {pages.map(({ path, title, component: Component }, index) => (
                  <Route key={`route-${index}`} path={path} element={<Component title={title} />} />
                ))}
              </Routes>
            </AppShell>
          </BrowserRouter>
        </SettingsProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
}

export default App;
