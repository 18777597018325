import { Text, Container, Image } from "@mantine/core";
import { useContext } from "react";
import { SettingsContext } from "../providers/SettingsProvider";

export default function About({ title }: { title: string }) {
  const { data } = useContext(SettingsContext);
  const photos = data ? data[title.split(' ').join('-').toLowerCase()] : [];

  return (
    <Container>
      {(photos && photos.length > 0) && (
        <Image
          radius="md"
          src={photos[0].url}
          alt={`${title} image number 1`}
          fit="contain"
          width="100%"
          height="calc(100vh - 50px)"
          withPlaceholder
          style={{
            display: 'block'
          }}
        />
      )}

      <Text style={{ maxWidth: 500, display: 'block', margin: '20px auto' }}>
        On location in Terzo D' Aguileia, Italy shooting for Porte Italia. He lives in Northeastern, CT with his wife and daughter.
      </Text>
    </Container>
  )
}