import { Anchor, Burger, createStyles, Drawer, Header } from '@mantine/core';
import logo from '../logo.svg';
import { Link } from "react-router-dom";
import { useState } from 'react';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    width: 60
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  },
  anchor: {
    color: '#212121',
    fontSize: '16px',
    lineHeight: '18px',
    fontFamily: 'Fjalla One, sans-serif',
    padding: '7px 0',
    position: 'relative',
    textDecoration: 'none'
  }
}));

export default function MobileHeader() {
  const [open, setOpened] = useState(false);
  const { classes } = useStyles();

  return (
    <div>
      <Header height="auto" padding="lg" style={{ borderBottom: 0 }}>
        <div className={classes.root}>
          <Anchor className={classes.logo} component={Link} to={'/'}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Anchor>
          <Burger
            opened={open}
            onClick={() => setOpened((o) => !o)}
          />
        </div>
        <Drawer
          opened={open}
          onClose={() => setOpened(false)}
          position="right"
          overlayOpacity={0.1}
          shadow="xl"
          size="xs"
          padding="sm"
          target=".mantine-AppShell-body"
        >
          <ul className={classes.menu}>
            <Anchor className={classes.anchor} component={Link} to={'/interiors'}>
              Interiors
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/exteriors'}>
              Exteriors
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/published'}>
              Published
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/still-life'}>
              Still Life
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/my-art'}>
              My Art
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/about'}>
              About
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to={'/contact'}>
              Contact
            </Anchor>
          </ul>
        </Drawer>
      </Header>
    </div>
  )
}