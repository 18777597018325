import { Container, Image } from "@mantine/core";
import { useContext } from "react";
import { SettingsContext } from "../providers/SettingsProvider";

export default function Home({ title }: { title: string }) {
  const { data } = useContext(SettingsContext);
  const photos = data ? data[title.split(' ').join('-').toLowerCase()] : [];

  return (
    <Container style={{ maxWidth: '100%' }}>
      {(photos && photos.length > 0) && (
        <Image
          radius="md"
          src={photos[0].url}
          alt={`${title} image number 1`}
          fit="contain"
          width="100%"
          height="calc(100vh - 50px)"
          withPlaceholder
          style={{
            display: 'block'
          }}
        />
      )}
    </Container>
  )
}